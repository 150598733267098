import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import { BsCheckCircle } from "react-icons/bs"
import _ from "lodash"
import { saveInvoice, clearInvoice } from "../state/actions/invoice"
import { addQuotation, deleteQuotation } from "../state/actions/quotation"
import { setProductsFromDatabase } from "../state/actions/product"
import PDFGenerator from "./pdfGenerator"
import DocumentPreview from "./documentPreview"
import { toast } from "react-toastify"

import styles from "../styles/components/previewPg.module.scss"

const PreviewPg = props => {
  //Has the invoice been saved
  const [isSaved, setIsSaved] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [documentData, setDocumentData] = useState({})

  const saveBtnHandler = async () => {
    setIsLoading(true)
    try {
      if (props.document === "quotation") {
        const quoteNumber = await props.addQuotation()
        setDocumentData({ ...documentData, documentNumber: quoteNumber })
      } else {
        const invNum = await props.saveInvoice()

        if (localStorage.getItem("quatationToInvoice")) {
          //delete Quotation
          await props.deleteQuotation(props.quoteData.id)
        }

        // await props.setProductsFromDatabase()
        props.clearInvoice()
        setDocumentData({ ...documentData, documentNumber: invNum })
        toast.success("Success: Invoice Saved!")
      }
      setIsSaved(true)
    } catch (e) {
      toast.error(`There was an error saving the document. ${e}`)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (props.document === "quotation") {
      setDocumentData({ ...props.quoteData })
    } else {
      setDocumentData({
        senderAddress: props.senderAddress,
        senderCity: props.senderCity,
        senderTel: props.senderTel,
        customer: props.customer,
        date: props.date,
        paymentMethod: props.paymentMethod,
        salesManager: props.salesManager,
        transport: props.transport,
        paymentTerms: props.paymentTerms,
        dueDate: props.dueDate,
        deliverTo: props.deliverTo,
        invoiceProducts: props.invoiceProducts,
      })
    }
  }, [props.document])

  return (
    <React.Fragment>
      {isSaved ? (
        <div>
          <Card className="text-center" style={{ marginBottom: "10px" }}>
            <Card.Body>
              <BsCheckCircle style={{ fontSize: "4rem" }} />
              <h1>
                <span style={{ fontWeight: "bold" }}>
                  {props.document === "quotation"
                    ? `Quotation Saved`
                    : `Invoice Saved`}
                </span>
              </h1>
              <h4>
                <span style={{ fontWeight: "bold" }}>
                  {props.document === "quotation"
                    ? `Quotation Number: `
                    : `Invoice Number: `}
                </span>
                {documentData.documentNumber}
              </h4>
              <h4>
                <span style={{ fontWeight: "bold" }}>Customer Name: </span>
                {documentData.customer.name}
              </h4>
            </Card.Body>
          </Card>
          <PDFGenerator document={props.document} data={documentData} />
        </div>
      ) : (
        <>
          {Object.keys(documentData).length !== 0 && (
            <div>
              <DocumentPreview data={documentData} document={props.document} />
              <Button
                variant="primary"
                className={styles.saveBtn}
                onClick={() => saveBtnHandler()}
                disabled={isLoading}
              >
                Save {props.document === "quotation" ? `Quotation` : `Invoice`}
              </Button>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    invoiceProducts: state.invoice.invoiceProducts,
    senderAddress: state.invoice.senderAddress,
    senderCity: state.invoice.senderCity,
    senderTel: state.invoice.senderTel,
    date: state.invoice.date,
    invoiceNumber: state.invoice.invoiceNumber,
    paymentMethod: state.invoice.paymentMethod,
    salesManager: state.invoice.salesManager,
    transport: state.invoice.transport,
    paymentTerms: state.invoice.paymentTerms,
    dueDate: state.invoice.dueDate,
    deliverTo: state.invoice.deliverTo,
    customer: state.invoice.customer,
    quoteData: state.quotation.newQuotation,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveInvoice: () => dispatch(saveInvoice()),
    addQuotation: () => dispatch(addQuotation()),
    setProductsFromDatabase: () => dispatch(setProductsFromDatabase()),
    clearInvoice: () => dispatch(clearInvoice()),
    deleteQuotation: id => dispatch(deleteQuotation(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPg)
