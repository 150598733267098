import { toast } from "react-toastify"
import axios from "axios"
import _ from "lodash"

export const setCustomers = () => {
  return async (dispatch, getState) => {
    if (
      getState().customer.customers &&
      getState().customer.customers.length > 0
    ) {
      return
    }
    const token = getState().auth.auth.token
    try {
      const data = await axios.get(`${process.env.GATSBY_API_CALL}/customers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      dispatch({
        type: "SET_CUSTOMERS",
        data: data.data.sort((a, b) => {
          return Number(a.code.split("-")) - Number(b.code.split("-"))
        }),
      })
    } catch (e) {
      console.log(e)
      toast.error(`Error. ${e.response.data.error}`)
    }
  }
}

export const addCustomer = customerData => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      const data = await axios.post(
        `${process.env.GATSBY_API_CALL}/customers`,
        customerData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const newCustomers = getState().customer.customers.concat(data.data)
      dispatch({
        type: "SET_CUSTOMERS",
        data: newCustomers,
      })
      toast.success("Success: Customer added!")
      return true
    } catch (e) {
      if (e.response.data.code === 11000) {
        toast.error("Error: Duplicate customer code.")
      } else {
        toast.error(`Error: ${e.response.data.message}`)
      }
    }
  }
}

// Patch Product
export const editCustomer = data => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    dispatch({
      type: "SET_EDIT_CUSTOMER_LOADING",
      data: true,
    })

    try {
      const newCustomer = await axios.patch(
        `${process.env.GATSBY_API_CALL}/customers/${data.id}`,
        data.customerData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      // Update Customers
      const newCustomers = [...getState().customer.customers]
      _.forEach(newCustomers, customer => {
        if (customer._id === newCustomer.data._id) {
          customer.code = newCustomer.data.code
          customer.name = newCustomer.data.name
          customer.address = newCustomer.data.address
          customer.telephone = newCustomer.data.telephone
          customer.email = newCustomer.data.email
        }
      })
      dispatch({
        type: "SET_CUSTOMERS",
        data: newCustomers,
      })

      toast.success(`Success: Customer updated!`)
      return true
    } catch (e) {
      toast.error(`Error: ${e.response.data.message}`)
    }
    dispatch({
      type: "SET_EDIT_CUSTOMER_LOADING",
      data: false,
    })
  }
}

// Delete Customer
export const deleteCustomer = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      await axios.delete(`${process.env.GATSBY_API_CALL}/customers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const customers = [...getState().customer.customers]
      const newCustomers = customers.filter(customer => {
        if (customer._id !== id) {
          return customer
        }
        return null
      })

      dispatch({
        type: "SET_CUSTOMERS",
        data: newCustomers,
      })
      toast.success("Success: Customer deleted!")
      return true
    } catch (e) {
      toast.error(`Error: ${e.response.statusText}`)
    }
  }
}
