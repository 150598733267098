const initialState = {
  deliveryNotes: [],
  isLoading: false
}

const DeliveryNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `SET_DELIVERY_NOTES`:
      return {
        ...state,
        deliveryNotes: action.data
      }
    case 'SET_DELIVERY_NOTES_LOADING':
      return {
        ...state,
        isLoading: action.data
      }
    default:
      return state
  }
}

export default DeliveryNotesReducer
