import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { toast } from "react-toastify"
import bsCustomFileInput from "bs-custom-file-input"
import * as XLSX from "xlsx"
import { connect } from "react-redux"
import {
  updatePriceInBatch,
  setProductsFromDatabase,
} from "../state/actions/product"
import { navigate } from "gatsby-link"
import { formatNumber } from "../state/utils"
import { Form, Button } from "react-bootstrap"
import { FaHome, FaSearch } from "react-icons/fa"
import Loading from "./loading"

// Styles
import styles from "../styles/components/table.module.scss"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const ProductTable = ({ setProducts, products, updatePriceInBatch, admin }) => {
  const [searchTerm, setSearchTerm] = useState("")

  const filteredProducts = products.filter(product => {
    const search = searchTerm?.trim().toLowerCase()
    const sku = product.sku?.trim().toLowerCase()
    const productName = product.productName?.trim().toLowerCase()
    const productDescription = product.productDescription?.trim().toLowerCase()
    const category = product.category?.trim().toLowerCase()
    const manufacturer = product.manufacturer?.trim().toLowerCase()

    if (
      sku?.includes(search) ||
      productName?.includes(search) ||
      productDescription?.includes(search) ||
      category?.includes(search) ||
      manufacturer?.includes(search)
    ) {
      return product
    }
  })

  useEffect(() => {
    bsCustomFileInput.init()
  }, [])

  useEffect(() => {
    setProductsFromDatabase()
  }, [])

  const classes = useStyles()

  const handlePriceListUpload = e => {
    if (!admin) {
      toast.error("Admin privileges required to upload a price/cost list.")
      return
    }

    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = e => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: "array" })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = XLSX.utils.sheet_to_json(worksheet)
        updatePriceInBatch(json)
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
    document.getElementById("price-file").value = null
  }

  if (products.length === 0) {
    return <Loading />
  }

  return (
    <div className={styles.wrapper}>
      <h1 className="title">
        <FaHome />
        Products
      </h1>
      {/* Excel Sheet uploads */}
      {admin && (
        <div className="mt-3">
          <Form.File
            style={{
              backgroundColor: "#f8f9fa",
              width: "fit-content",
              fontSize: "1.3rem",
              border: "1px solid #ced4da",
              marginTop: "16px",
            }}
            id="price-file"
            label="Update product prices &amp; costs"
            custom
            onChange={handlePriceListUpload}
          />
        </div>
      )}
      {/* Search */}
      <div className={styles.search}>
        <input
          type="text"
          value={searchTerm}
          name="search"
          placeholder="Search by SKU, Name, Description, Category or Manufacturer"
          autoComplete="off"
          className={styles.searchInput}
          onChange={e => {
            setSearchTerm(e.target.value)
          }}
        />
        <FaSearch className={styles.searchIcon} />
      </div>
      <div className="d-flex flex-wrap">
        <Button
          variant="secondary"
          className="mr-3 mb-3"
          onClick={e => setSearchTerm("")}
        >
          All Products
        </Button>
        <Button
          variant="secondary"
          className="mr-3 mb-3"
          onClick={e => setSearchTerm("Solar Inverter")}
        >
          Inverters
        </Button>
        <Button
          variant="secondary"
          className="mr-3 mb-3"
          onClick={e => setSearchTerm("PV Modules")}
        >
          Panels
        </Button>
        <Button
          variant="secondary"
          className="mr-3 mb-3"
          onClick={e => setSearchTerm("Railings & Accessories")}
        >
          Railings
        </Button>
        <Button
          variant="secondary"
          className="mr-3 mb-3"
          onClick={e => setSearchTerm("Electrical Accessories")}
        >
          Electrical
        </Button>
        <Button
          variant="secondary"
          className="mr-3 mb-3"
          onClick={e => setSearchTerm("Hardware Accessories")}
        >
          Hardware
        </Button>
        <Button
          variant="secondary"
          className="mr-3 mb-3"
          onClick={e => setSearchTerm("DC & AC Cables")}
        >
          DC &amp; AC
        </Button>

        <Button
          variant="secondary"
          className="mr-3 mb-3"
          onClick={e => setSearchTerm("Solar Water Pump")}
        >
          Water Pump
        </Button>
      </div>
      {products.length > 0 && (
        <TableContainer component={Paper} style={{ margin: "20px 0" }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Product Description</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Warranty</TableCell>
                <TableCell>Country Of Origin</TableCell>
                <TableCell>Stock in Hand</TableCell>
                <TableCell>Stock in Transit</TableCell>
                <TableCell>Price</TableCell>
                {admin && <TableCell>Cost</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProducts.map(node => {
                return (
                  <TableRow
                    style={{ cursor: "pointer" }}
                    key={node.sku}
                    onClick={() => {
                      navigate(`/app/edit-product/${node.id}`)
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {node.sku}
                    </TableCell>
                    <TableCell>{node.productName}</TableCell>

                    <TableCell>{node.productDescription}</TableCell>
                    <TableCell>
                      {node.productImages &&
                        node.productImages.map((image, i) => (
                          <div className={styles.img} key={i}>
                            <img
                              src={image.fields.file.url}
                              alt={image.fields.description}
                              style={{
                                display: "block",
                                maxWidth: "100%",
                                maxHeight: "100%",
                                width: "auto",
                                height: "auto",
                              }}
                            />
                          </div>
                        ))}
                    </TableCell>
                    <TableCell>{node.category}</TableCell>
                    <TableCell>{node.manufacturer}</TableCell>
                    <TableCell>{node.warranty}</TableCell>
                    <TableCell>{node.countryOfOrigin}</TableCell>
                    <TableCell>{node.stock}</TableCell>
                    <TableCell>{node.stockInTransit}</TableCell>
                    <TableCell>{formatNumber(node.price)}</TableCell>
                    {admin && <TableCell>{formatNumber(node.cost)}</TableCell>}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    products: state.product.products,
    admin: state.auth.auth.user.admin,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updatePriceInBatch: data => dispatch(updatePriceInBatch(data)),
    setProductsFromDatabase: () => dispatch(setProductsFromDatabase()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTable)
