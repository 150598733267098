import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Button, Table } from 'react-bootstrap'
import { HiOutlineDocumentAdd } from 'react-icons/hi'
import { navigate } from 'gatsby'
import moment from 'moment'
import { getQuotations, clearNewQuotation } from '../../state/actions/quotation'
import { setUsers } from '../../state/actions/user'
import {
  FaFileInvoiceDollar,
  FaArrowLeft,
  FaArrowRight,
  FaSearch
} from 'react-icons/fa'
import Loading from '../loading'

import styles from '../../styles/components/quotations/quotations.module.scss'

const Quotations = ({
  quotations,
  customers,
  users,
  getQuotations,
  setUsers,
  clearNewQuotation
}) => {
  useEffect(() => {
    setUsers()
    getQuotations()
  }, [getQuotations])

  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 25

  const filteredQuotations = quotations
    .filter(quotation => {
      const search = searchTerm.trim().toLowerCase()
      const quoteNum = quotation.quotationNumber.trim().toLowerCase()
      const cusCode = quotation.customerCode.trim().toLowerCase()
      const cusName =
        customers
          .find(customer => customer.code === quotation.customerCode)
          ?.name.trim()
          .toLowerCase() || ''
      const date = quotation.date.trim()
      const uploader = users
        .find(user => user._id === quotation.uploader)
        ?.name.trim()
        .toLowerCase()

      if (
        quoteNum?.includes(search) ||
        cusCode?.includes(search) ||
        cusName?.includes(search) ||
        date?.includes(search) ||
        uploader?.includes(search)
      ) {
        return quotation
      }
    })
    .reverse()
    .filter(
      quotation =>
        quotation?.deleted == undefined || quotation?.deleted == false
    )

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentQuotations = filteredQuotations.slice(
    indexOfFirstItem,
    indexOfLastItem
  )

  // if (!quotations.length) return <Loading text='Loading Quotations ..' />

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h1 className='title'>
          <FaFileInvoiceDollar />
          Quotations
        </h1>
        <div className='action-buttons'>
          <Button
            size='lg'
            variant='success'
            onClick={() => {
              clearNewQuotation()
              navigate('/app/quotations/new')
            }}
          >
            <HiOutlineDocumentAdd
              className='mb-1 mr-2'
              style={{ fontSize: '16px' }}
            />
            New Quotation
          </Button>
        </div>
      </div>
      <div className='mt-5'>
        {quotations.length ? (
          <>
            {/* Search */}
            <div className={styles.search}>
              <input
                type='text'
                value={searchTerm}
                name='search'
                placeholder='Search'
                autoComplete='off'
                className={styles.searchInput}
                onChange={e => {
                  setSearchTerm(e.target.value)
                  setCurrentPage(1)
                }}
              />
              <FaSearch className={styles.searchIcon} />
            </div>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Quotation Number</th>
                  <th>Customer</th>
                  <th>Date</th>
                  <th>Created By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {currentQuotations.map(quotation => (
                  <tr
                    key={quotation._id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/app/quotations/${quotation._id}`)
                    }}
                  >
                    <td>{quotation.quotationNumber}</td>
                    <td>
                      {quotation.customerCode} -{' '}
                      {
                        customers.find(
                          customer => customer.code === quotation.customerCode
                        )?.name
                      }
                    </td>
                    <td>
                      {moment(new Date(quotation.date)).format('DD-MM-YYYY')}
                    </td>
                    <td>
                      {
                        users.find(user => user._id === quotation.uploader)
                          ?.name
                      }
                    </td>
                    <td>
                      {quotation.cancelled ? (
                        <span className='text-danger'>Cancelled</span>
                      ) : (
                        <span className='text-success'>Active</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Pagination */}
            <Row className='d-flex justify-content-center align-items-center mx-4 my-3'>
              <Button
                size='lg'
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                variant={currentPage === 1 ? 'secondary' : 'primary'}
              >
                <FaArrowLeft />
              </Button>

              <span className='mx-3 small'>
                Page {currentPage} /{' '}
                {
                  // Total pages
                  Math.ceil(filteredQuotations.length / itemsPerPage)
                }
              </span>

              <Button
                size='lg'
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={indexOfLastItem >= filteredQuotations.length}
              >
                <FaArrowRight />
              </Button>
            </Row>
          </>
        ) : (
          <p className='text-muted'>No quotations available!</p>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    quotations: state.quotation.quotations,
    customers: state.customer.customers,
    users: state.user.users
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getQuotations: () => dispatch(getQuotations()),
    setUsers: () => dispatch(setUsers()),

    // Clear New Quotation
    clearNewQuotation: () => dispatch(clearNewQuotation())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Quotations)
