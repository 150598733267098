import React, { useEffect } from 'react'
import { getDeliveryNotes } from '../../state/actions/deliveryNotes'
import { setInvoices } from '../../state/actions/invoices'
import { connect } from 'react-redux'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import InvoiceReport from './downloadReports/invoiceReport'
import ItemReport from './downloadReports/itemReport'
import IssuesReport from './downloadReports/issuesReport'
import InStockReport from './downloadReports/instockReport'
import { FaChartBar } from 'react-icons/fa'

import styles from '../../styles/components/invoices/invoices.module.scss'
import { navigate } from 'gatsby'

const Reports = props => {
  useEffect(() => {
    if (!props.admin && !props.reports) {
      navigate('/app/products')
    }
    props.getDeliveryNotes()
    props.setInvoices()
  }, [])

  const arr = []
  for (let i = 0; i < props.invoices.length; i++) {
    if (props.invoices[i].cancelled == false) {
      for (let j = 0; j < props.invoices[i].invoiceProducts.length; j++) {
        for (let k = 0; k < props.products.length; k++) {
          if (
            props.invoices[i].invoiceProducts[j].sku == props.products[k].sku
          ) {
            let customer

            for (let m = 0; m < props.customers.length; m++) {
              if (props.invoices[i].customerCode == props.customers[m].code) {
                customer = props.customers[m].name
              }
            }

            arr.push({
              sku: props.invoices[i].invoiceProducts[j].sku,
              newWarranty: props.invoices[i].invoiceProducts[j]?.newWarranty,
              serial: props.invoices[i].invoiceProducts[j]?.serialNumbers,
              price: props.invoices[i].invoiceProducts[j].price,
              invNo: props.invoices[i].invoiceNumber,
              cost: props.products[k].cost,
              fullprice:
                props.invoices[i].invoiceProducts[j].price *
                props.invoices[i].invoiceProducts[j].quantity,
              quantity: props.invoices[i].invoiceProducts[j].quantity,
              InvDate: props.invoices[i].date,
              discountType: props.invoices[i].invoiceProducts[j]?.discountType,
              discount: props.invoices[i].invoiceProducts[j].discount,
              customer: customer
            })
          }
          // sku:products[i].sku,price:products[i].price,invNo:products[i].invoices[j].invoiceNumber ,cost:products[i].cost ,fullprice:products[i].invoices[j].invoiceProducts[k].price ,quantity:products[i].invoices[j].invoiceProducts[k].quantity,InvDate:products[i].invoices[j].date, dispatchDate:products[i].invoices[j].dispatchDate,discountType:products[i].invoices[j].invoiceProducts[k].discountType ,discount:products[i].invoices[j].invoiceProducts[k].discount
        }
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <h1 className='title'>
        <FaChartBar />
        Reports
      </h1>

      <Tabs
        defaultActiveKey='invoice'
        id='uncontrolled-tab-example'
        className='my-4'
      >
        <Tab eventKey='invoice' title='Invoice'>
          <InvoiceReport
            arr={arr}
            products={props.products}
            deliveryNotes={props.deliveryNotes}
          >
            Invoice
          </InvoiceReport>
        </Tab>
        <Tab eventKey='items' title='Items'>
          <ItemReport
            arr={arr}
            products={props.products}
            deliveryNotes={props.deliveryNotes}
          >
            Items
          </ItemReport>
        </Tab>
        <Tab eventKey='issues' title='Issues'>
          <IssuesReport
            arr={arr}
            products={props.products}
            deliveryNotes={props.deliveryNotes}
          >
            Issues
          </IssuesReport>
        </Tab>
        <Tab eventKey='in-stock' title='Stock'>
          <InStockReport
            arr={arr}
            products={props.products}
            deliveryNotes={props.deliveryNotes}
          >
            In Stock
          </InStockReport>
        </Tab>
        {/* <Tab eventKey='sales' title='Sales'>
          {arr.length > 0 ? (
            <SalesReport arr={arr}></SalesReport>
          ) : (
            <div className='text-secondary'>Loading...</div>
          )}
        </Tab>
        <Tab eventKey='SerialNumbers' title='Serial Numbers'>
          {arr.length > 0 ? (
            <SerialNoReport arr={arr} deliveryNotes={props.deliveryNotes}>
              Serial Numbers
            </SerialNoReport>
          ) : (
            <div className='text-secondary'>Loading...</div>
          )}
        </Tab>
        <Tab eventKey='warranty' title='Warranty'>
          {arr.length > 0 ? (
            <WarrantyReport
              arr={arr}
              products={props.products}
              deliveryNotes={props.deliveryNotes}
            >
              Warranty
            </WarrantyReport>
          ) : (
            <div className='text-secondary'>Loading...</div>
          )}
        </Tab> */}
      </Tabs>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    quotations: state.quotation.quotations,
    customers: state.customer.customers,
    products: state.product.products,
    invoices: state.invoices.invoices,
    deliveryNotes: state.deliveryNotes.deliveryNotes,
    admin: state.auth?.auth?.user?.admin,
    reports: state.auth?.auth?.user?.reports
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDeliveryNotes: () => dispatch(getDeliveryNotes()),
    setInvoices: () => dispatch(setInvoices())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
