import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table, Row, Button } from 'react-bootstrap'
import { navigate } from 'gatsby'
import moment from 'moment'
import { getDeliveryNotes } from '../../state/actions/deliveryNotes'
import { setInvoices } from '../../state/actions/invoices'
import { FaTruck, FaArrowLeft, FaArrowRight, FaSearch } from 'react-icons/fa'

import styles from '../../styles/components/deliveryNotes/deliveryNotes.module.scss'
import Loading from '../loading'

const DeliveryNotes = ({
  deliveryNotes,
  getDeliveryNotes,
  customers,
  setInvoices,
  isLoading
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 25

  useEffect(() => {
    getDeliveryNotes()
    setInvoices()
  }, [])

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const filteredDeliveryNotes = deliveryNotes
    .filter(dn => {
      const search = searchTerm.trim().toLowerCase()
      const dnNum = dn.deliveryNoteNumber.trim().toLowerCase()
      const invNum = dn.invoiceNumber.trim().toLowerCase()
      const cusCode = dn.customerCode.trim().toLowerCase()
      const cusName =
        customers
          .find(customer => customer.code === dn.customerCode)
          ?.name.trim()
          .toLowerCase() || ''
      const date = dn.date?.trim()

      return (
        dnNum.includes(search) ||
        invNum.includes(search) ||
        cusCode.includes(search) ||
        cusName.includes(search) ||
        (date && date.includes(search))
      )
    })
    .reverse()

  const currentDeliveryNotes = filteredDeliveryNotes.slice(
    indexOfFirstItem,
    indexOfLastItem
  )

  // if (deliveryNotes.length === 0) return <Loading />

  return (
    <div className={styles.wrapper}>
      <h1 className='title'>
        <FaTruck />
        Delivery Notes
      </h1>
      <div className={styles.search}>
        <input
          type='text'
          value={searchTerm}
          name='search'
          placeholder='Search'
          autoComplete='off'
          className={styles.searchInput}
          onChange={e => {
            setSearchTerm(e.target.value)
            setCurrentPage(1)
          }}
        />
        <FaSearch className={styles.searchIcon} />
      </div>
      {filteredDeliveryNotes.length ? (
        <>
          {/* Search */}
          <Table hover responsive>
            <thead>
              <tr>
                <th>Delivery Note Number</th>
                <th>Invoice Number</th>
                <th>Customer</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentDeliveryNotes.map(dn => (
                <tr
                  key={dn._id}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate(`/app/delivery-notes/${dn.deliveryNoteNumber}`)
                  }
                >
                  <td>{dn.deliveryNoteNumber}</td>
                  <td>{dn.invoiceNumber}</td>
                  <td>{`${dn.customerCode} - ${
                    customers.find(
                      customer => customer.code === dn.customerCode
                    )?.name
                  }`}</td>
                  <td>{moment(new Date(dn.date)).format('DD-MM-YYYY')}</td>
                  <td
                    className={
                      dn.status === 'Cancelled'
                        ? 'text-danger'
                        : dn.status === 'Delivered'
                        ? 'text-success'
                        : ''
                    }
                  >
                    {dn.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination */}
          <Row className='d-flex justify-content-center align-items-center mx-4'>
            <Button
              size='lg'
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              variant={currentPage === 1 ? 'secondary' : 'primary'}
            >
              <FaArrowLeft />
            </Button>

            <span className='mx-3 small'>
              Page {currentPage} /{' '}
              {
                // Total pages
                Math.ceil(filteredDeliveryNotes.length / itemsPerPage)
              }
            </span>

            <Button
              size='lg'
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={indexOfLastItem >= filteredDeliveryNotes.length}
            >
              <FaArrowRight />
            </Button>
          </Row>
        </>
      ) : (
        <p className='text-muted'>No Delivery Notes</p>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    deliveryNotes: state.deliveryNotes.deliveryNotes,
    customers: state.customer.customers,
    isLoading: state.deliveryNotes.isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDeliveryNotes: () => dispatch(getDeliveryNotes()),
    setInvoices: () => dispatch(setInvoices())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryNotes)
