import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import PreviewPg from "../previewPg"
import ProductModal from "../productModal"
import ProductTable from "../productTable"
import { Form, Button, Row, Col } from "react-bootstrap"
import Select from "react-select"
import { setCustomers } from "../../state/actions/customer"
import { setCustomer } from "../../state/actions/invoice"
import BackButton from "../backButton"

// Styles
import styles from "../../styles/components/invoices/newInvoice.module.scss"
import { toast } from "react-toastify"

const NewInvoice = ({
  getCustomers,
  customers,
  date,
  setDate,
  setSenderAddress,
  setSenderCity,
  setSenderTel,
  paymentMethod,
  setPaymentMethod,
  salesManager,
  transport,
  setSalesManager,
  setTransport,
  paymentTerms,
  setPaymentTerms,
  dueDate,
  setDueDate,
  deliverTo,
  setDeliverTo,
  customer,
  setCustomer,
  invoiceProducts,
}) => {
  const [showPreview, setShowPreview] = useState(false)

  useEffect(() => {
    if (customers.length && Object.keys(customer).length === 0) {
      handleSetCustomer(customers[0]._id)
    } else {
      getCustomers()
    }
  }, [customers.length])

  const customerOptions = customers.map(customer => {
    return {
      value: customer._id,
      label: `${customer.code} - ${customer.name}`,
    }
  })

  const handleSetCustomer = id => {
    setCustomer(id)
  }

  const handleInputChange = e => {
    e.preventDefault()
    setShowPreview(false)
    switch (e.target.name) {
      case "date":
        setDate(e.target.value)
        break
      case "senderAddress":
        setSenderAddress(e.target.value)
        break
      case "senderCity":
        setSenderCity(e.target.value)
        break
      case "senderTel":
        setSenderTel(e.target.value)
        break
      case "paymentMethod":
        setPaymentMethod(e.target.value)
        break
      case "salesManager":
        setSalesManager(e.target.value)
        break
      case "paymentTerms":
        setPaymentTerms(e.target.value)
        break
      case "dueDate":
        setDueDate(e.target.value)
        break
      case "transport":
        setTransport(e.target.value)
        break
      case "deliverTo":
        setDeliverTo(e.target.value)
        break
      default:
        return
    }
  }
  return (
    <div className={styles.wrapper}>
      <h1 className="title">New Invoice</h1>
      {!showPreview && (
        <>
          <BackButton to="/app/invoices" />
          <div className={styles.form}>
            <div className={styles.invoice}>
              <h2>Invoice Details</h2>
              <Form.Group className="form-group">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  size="lg"
                  value={date}
                  name="date"
                  type="date"
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Payment Method</Form.Label>
                <Form.Control
                  as="select"
                  size="lg"
                  name="paymentMethod"
                  value={paymentMethod}
                  onChange={handleInputChange}
                >
                  <option>Cash</option>
                  <option>Cheque</option>
                  <option>Bank Transfer</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label> Sales Manager</Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Sales Manager"
                  value={salesManager}
                  name="salesManager"
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* <Form.Group className="form-group">
                <Form.Label> Transport</Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Sales Manager"
                  value={transport}
                  name="transport"
                  onChange={handleInputChange}
                />
              </Form.Group> */}

              <Form.Group>
                <Form.Label> Payment Terms</Form.Label>
                <Form.Control
                  as="select"
                  size="lg"
                  onChange={handleInputChange}
                  name="paymentTerms"
                  value={paymentTerms}
                >
                  <option>Cash on Delivery</option>
                  <option>Advance Payment</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Due Date</Form.Label>
                <Form.Control
                  size="lg"
                  value={dueDate}
                  name="dueDate"
                  onChange={handleInputChange}
                  type="date"
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Delivery Location</Form.Label>
                <Form.Control
                  size="lg"
                  placeholder="Delivery Location"
                  value={deliverTo}
                  name="deliverTo"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>

            <div className={styles.client}>
              <h2>Customer Details</h2>
              <Form.Group>
                <Form.Label>Select Customer</Form.Label>
                <Select
                  isSearchable={true}
                  defaultValue={
                    customerOptions[
                      customerOptions.findIndex(c => c.value === customer._id)
                    ] || customerOptions[0]
                  }
                  options={customerOptions}
                  onChange={e => handleSetCustomer(e.value)}
                />
              </Form.Group>

              {customer && (
                <>
                  <Row style={{ marginTop: "25px" }}>
                    <Col sm="3">
                      <p>Customer Code</p>
                    </Col>
                    <Col sm="9">
                      <p>{customer.code}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <p>Address</p>
                    </Col>
                    <Col sm="9">
                      <p>{customer.address}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <p>Telephone</p>
                    </Col>
                    <Col sm="9">
                      <p>{customer.telephone}</p>
                    </Col>
                  </Row>
                  {customer.email && (
                    <Row>
                      <Col sm="3">
                        <p>Email</p>
                      </Col>
                      <Col sm="9">
                        <p>{customer.email}</p>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </div>

          {/* Product Table & Product Select */}

          <div className={styles.productTable}>
            <h2 className={styles.productTableHeading}>Products</h2>
            <ProductTable document="invoice" />
            <ProductModal document="invoice" />
          </div>
        </>
      )}

      {/* Generate Invoice */}
      {showPreview && <PreviewPg document="invoice" />}
      <Button
        className={styles.btn}
        variant="light"
        onClick={() => {
          if (
            !showPreview &&
            invoiceProducts.some(
              product =>
                product.includeSerialNumbers &&
                product.serialNumbersInInvoice.length !== product.quantity
            )
          ) {
            toast.error(
              "The serial number count of some products does not match the quantity."
            )
          } else {
            setShowPreview(!showPreview)
          }
        }}
      >
        {!showPreview ? `Generate Preview` : `Back to Edit Invoice`}
      </Button>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    invoiceProducts: state.invoice.invoiceProducts,
    senderAddress: state.invoice.senderAddress,
    senderCity: state.invoice.senderCity,
    senderTel: state.invoice.senderTel,
    date: state.invoice.date,
    paymentMethod: state.invoice.paymentMethod,
    salesManager: state.invoice.salesManager,
    transport:state.invoice.transport,
    paymentTerms: state.invoice.paymentTerms,
    dueDate: state.invoice.dueDate,
    deliverTo: state.invoice.deliverTo,
    customers: state.customer.customers,
    customer: state.invoice.customer,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setDate: data => dispatch({ type: `SET_DATE`, data }),
    setPaymentMethod: data => dispatch({ type: `SET_PAYMENT_METHOD`, data }),
    setSalesManager: data => dispatch({ type: `SET_SALES_MANAGER`, data }),
    setTransport: data => dispatch({ type: `SET_TRANSPORT`, data }),
    setPaymentTerms: data => dispatch({ type: `SET_PAYMENT_TERMS`, data }),
    setDueDate: data => dispatch({ type: `SET_DUE_DATE`, data }),
    setDeliverTo: data => dispatch({ type: `SET_DELIVER_TO`, data }),
    getCustomers: () => dispatch(setCustomers()),
    setCustomer: data => dispatch(setCustomer(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice)
