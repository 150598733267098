import { toast } from "react-toastify"
import axios from "axios"

// Get all invoices from db
export const setInvoices = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: `SET_INVOICE_LOADING`,
      data: true,
    })

    const token = getState().auth.auth.token
    try {
      const res = await axios.get(`${process.env.GATSBY_API_CALL}/invoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const invoices = res.data.sort(
        (a, b) =>
          Number(b.invoiceNumber.split("-")[1]) -
          Number(a.invoiceNumber.split("-")[1])
      )

      // Reverse the array data.data
      // await data.data.reverse()

      dispatch({
        type: "SET_INVOICES",
        data: invoices,
      })

      dispatch({
        type: `SET_INVOICE_LOADING`,
        data: false,
      })
    } catch (e) {
      console.log(e)
      toast.error(
        `There was an error fetching invoices. Please try again later.`
      )
      dispatch({
        type: `SET_INVOICE_LOADING`,
        data: false,
      })
    }
  }
}

export const reasonForCancle = (id, reason) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/reasonForCancle/${id}`,
        { reasonForCancel: reason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      toast.success("Success: Invoice cancelled Request Success!")
      return true
    } catch (e) {
      toast.error(`Error: ${e.response.data.message}`)
    }
  }
}

export const reverceReasonForCancle = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/reverseReasonForCancel/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      toast.success("Success: reversed cancelled Request Success!")
      return true
    } catch (e) {
      toast.error(`Error: ${e.response.data.message}`)
    }
  }
}

export const cancelInvoice = (id, products) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/invoices/${id}`,
        { cancelled: true, products },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      toast.success("Success: Invoice cancelled!")
      return true
    } catch (e) {
      toast.error(`Error: ${e.response.data.message}`)
    }
  }
}
