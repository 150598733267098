import React, { useMemo } from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import _ from "lodash"
import { Button } from "react-bootstrap"
import { formatNumber } from "../../state/utils"
import logo from "../../images/logo.png"

const PaymentReceiptPDF = ({ data }) => {
  // PDF Document
  const MyDocument = () => (
    <Document>
      <Page size="A5" style={styles.page} wrap>
        <Text style={styles.documentTitle}>Payment Receipt</Text>
        <View style={styles.header}>
          <View>
            {/* Logo */}
            <View style={styles.logo} fixed>
              <Image src={logo} />
            </View>

            {/* Sender Information */}
            <View style={styles.sender}>
              <Text>{data.senderAddress}</Text>
              <Text>{data.senderCity}</Text>
              <Text>{data.senderTel}</Text>
              <Text>info@wholesalesolar.lk / www.wholesalesolar.lk</Text>
            </View>
          </View>

          {/* Payment Receipt Information */}
          <View style={{ marginLeft: "auto" }}>
            <Text>Payment Receipt Number: {data.paymentReceiptNumber}</Text>
          </View>
        </View>

        {/* Body Data */}
        <View style={styles.mainContent}>
          <View style={styles.row}>
            <Text style={styles.cell1}>{`Payment for Invoice ${
              data.invoiceNumbers.length > 1 ? "Nos" : "No"
            }`}</Text>
            <Text style={styles.cell2}>{data.invoiceNumbers.join(", ")}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cell1}>Date</Text>
            <Text style={styles.cell2}>{data.date}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cell1}>Payment Method</Text>
            <Text style={styles.cell2}>{data.paymentMethod}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cell1}>Amount</Text>
            <Text style={styles.cell2}>{`LKR ${formatNumber(
              +data.amount
            )}`}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cell1}>Received From</Text>
            <Text
              style={styles.cell2}
            >{`${data.customer?.name} (${data.customer?.code})`}</Text>
          </View>
        </View>

        {/* Bank and Cheque Info  */}
        {data.paymentMethod !== "Cash" && (
          <View style={styles.bankTable}>
            {data.paymentMethod === "Cheque" && (
              <View style={styles.bankRow}>
                <Text style={styles.bankCell1}>Date</Text>
                <Text style={styles.bankCell2}>{data.chequeDate}</Text>
              </View>
            )}
            <View style={styles.bankRow}>
              <Text style={styles.bankCell1}>Bank</Text>
              <Text style={styles.bankCell2}>{data.bank}</Text>
            </View>
            {data.paymentMethod === "Cheque" && (
              <View style={styles.bankRow}>
                <Text style={styles.bankCell1}>Cheque No</Text>
                <Text style={styles.bankCell2}>{data.chequeNumber}</Text>
              </View>
            )}
            {data.paymentMethod === "Bank Transfer" && (
              <View style={styles.bankRow}>
                <Text style={styles.bankCell1}>Bank Ref</Text>
                <Text style={styles.bankCell2}>{data.bankRef}</Text>
              </View>
            )}
          </View>
        )}
        {data.paymentMethod === "Cheque" && (
          <View style={{ marginTop: 5, fontSize: 10 }}>
            <Text>
              This Payment Voucher will be valid only after realization of the
              Cheque
            </Text>
          </View>
        )}

        {/* Signatures */}
        <View style={styles.signatureTable}>
          <View style={styles.signatureDiv}>
            <Text style={styles.signatureRow}>Name</Text>
            <Text style={styles.signatureRow}>Receiver Signature</Text>
          </View>
          <View style={styles.signatureDiv}>
            <Text style={styles.signatureRow}>Name</Text>
            <Text style={styles.signatureRow}>Customer Signature</Text>
          </View>
        </View>
      </Page>
    </Document>
  )

  return useMemo(
    () => (
      <div>
        <PDFDownloadLink
          document={<MyDocument />}
          fileName={`${data.paymentReceiptNumber} ${data.customer?.name}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <p style={{ textTransform: "uppercase", textAlign: "center" }}>
                Loading Download
              </p>
            ) : (
              <p
                style={{
                  textTransform: "uppercase",
                  fontFamily: "Montserrat",
                  color: "#126b91",
                  fontWeight: "bold",
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    height: "48px",
                    fontSize: "1.5rem",
                  }}
                >
                  Download Payment Receipt
                </Button>
              </p>
            )
          }
        </PDFDownloadLink>
      </div>
    ),
    []
  )
}

// Stylesheet
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    paddingHorizontal: 30,
    fontSize: 12,
    width: "100%",
  },
  documentTitle: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 20,
  },
  header: {
    flexDirection: "row",
    marginTop: 20,
  },
  logo: {
    width: "50%",
    marginBottom: 8,
  },
  sender: {
    fontSize: 10,
  },
  mainContent: {
    marginTop: 20,
  },
  row: {
    flexDirection: "row",
  },
  cell1: {
    width: "40%",
    fontWeight: "bold",
  },
  bankTable: {
    marginTop: 20,
    width: "100%",
    border: true,
    borderBottom: false,
  },
  bankRow: {
    flexDirection: "row",
    borderBottom: true,
  },
  bankCell1: {
    width: "35%",
    borderRight: true,
    padding: 3,
  },
  bankCell2: {
    width: "65%",
    padding: 3,
  },
  signatureTable: {
    marginTop: 20,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signatureRow: {
    borderTop: true,
    borderStyle: "dotted",
    marginTop: 20,
    width: 125,
    paddingTop: 5,
  },
})

export default PaymentReceiptPDF
