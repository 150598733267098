import React, { useState } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { Modal, Button, Form } from "react-bootstrap"
import { FaWindowClose, FaSearch } from "react-icons/fa"
import { setInvoiceNumbers } from "../../state/actions/paymentReceipts"
import { formatNumber, getTotal } from "../../state/utils"

import styles from "../../styles/components/paymentReceipts/invoiceNumberModal.module.scss"

const InvoiceNumberModal = ({
  invoices,
  invoiceNumbersInPaymentReceipt,
  setInvoiceNumbers,
}) => {
  const [show, setShow] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  return (
    <>
      <Button onClick={() => setShow(true)} variant="secondary">
        Add
      </Button>

      {/* Invoice Number Modal */}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title>Add Invoice Numbers</Modal.Title>
          <Button
            tabIndex={2}
            variant="danger"
            onClick={() => setShow(false)}
            onKeyDown={() => setShow(false)}
          >
            <FaWindowClose />
          </Button>
        </Modal.Header>
        <Modal.Body className="h-100">
          {/* Search */}
          <div className={styles.search}>
            <input
              type="text"
              value={searchTerm}
              name="search"
              placeholder="Search"
              className={styles.searchInput}
              onChange={e => {
                setSearchTerm(e.target.value)
              }}
              autoComplete="off"
            />
            <FaSearch className={styles.searchIcon} />
          </div>

          {/* Invoice Numbers */}
          {invoices.length ? (
            <div className={styles.invList}>
              {invoices
                .filter(invoice => {
                  const search = searchTerm.toLowerCase()
                  const invNumber = invoice.invoiceNumber.toLowerCase()

                  if (invNumber.includes(search)) {
                    return invoice
                  } else return null
                }).filter((inv)=>inv.delivered==false)
                .sort((a, b) => {
                  if (
                    invoiceNumbersInPaymentReceipt.includes(a.invoiceNumber) &&
                    !invoiceNumbersInPaymentReceipt.includes(b.invoiceNumber)
                  ) {
                    return -1
                  } else if (
                    !invoiceNumbersInPaymentReceipt.includes(a.invoiceNumber) &&
                    invoiceNumbersInPaymentReceipt.includes(b.invoiceNumber)
                  ) {
                    return 1
                  } else {
                    return 0
                  }
                })
                .map(invoice => (
                  <Form.Group key={invoice.invoiceNumber}>
                    <Form.Check
                      className={styles.invoiceCheckbox}
                      type="checkbox"
                      label={`${
                        invoice.invoiceNumber
                      } (Invoice Total: LKR ${formatNumber(
                        getTotal(invoice.invoiceProducts)
                      )}) `}
                      checked={invoiceNumbersInPaymentReceipt.includes(
                        invoice.invoiceNumber
                      )}
                      onChange={() => setInvoiceNumbers(invoice.invoiceNumber)}
                    />
                  </Form.Group>
                ))}
            </div>
          ) : (
            <div>No invoice numbers available for this customer</div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    invoices: state.invoices.invoices.filter(
      inv =>
        inv.customerCode ===
        state.paymentReceipts.newPaymentReceipt.customer.code
    ),
    invoiceNumbersInPaymentReceipt:
      state.paymentReceipts.newPaymentReceipt.invoiceNumbers,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setInvoiceNumbers: number => dispatch(setInvoiceNumbers(number)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceNumberModal)
