import React,{useState,useEffect} from 'react'
import { DateRangePicker, DateRange } from "materialui-daterange-picker"
import styles from '../../styles/components/sales/sales.module.scss'
import moment from 'moment'
import { getTotal } from '../../state/utils'

const RangeCalender = (props) => {

  const [open, setOpen] = React.useState(true);
  const [dateRange, setDateRange] = React.useState({})
 
  const x=moment(dateRange.endDate).format('YYYY-MM-DD')
  const y= moment(dateRange.startDate).format('YYYY-MM-DD')
 

  const definedDispatchdates=props.data.filter(d=>d.InvDate!==undefined)
  
  
  const data=definedDispatchdates.map((rer)=>{
    return{
      ...rer,
      dispatchDate:moment(rer.InvDate).format('YYYY-MM-DD')
    }   
  })

  

  const sales=data.filter(d=> moment(d.dispatchDate).isBetween(y,x,null,[]) )
  

  const allSales=getTotal(sales)

  let totalCost=0
  for(let product=0;product<sales.length;product++){
   totalCost=totalCost+sales[product].cost*sales[product].quantity
  }

  console.log(sales)

  
  props.setCostPerDate(totalCost)
  props.setProfitPerDate(allSales-totalCost)
  props.setPricePerDate(allSales)

  return (
    <div className={`${styles.calanderRange}`}>
     
        <DateRangePicker 
            wrapperClassName={styles.clender}  
            startText="Check-in"
            endText="Check-out"
            open={open}    
            onChange={(range) => setDateRange(range)}
        />
    
    </div>
  )
}

export default RangeCalender