import { toast } from 'react-toastify'
import axios from 'axios'

// Get all delivery notes from db
export const getDeliveryNotes = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    dispatch({
      type: `SET_DELIVERY_NOTES_LOADING`,
      data: true
    })

    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/delivery-notes`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      const deliveryNotes = res.data.sort(
        (a, b) =>
          Number(a.deliveryNoteNumber.split("-")[1]) -
          Number(b.deliveryNoteNumber.split("-")[1])
      )

      dispatch({
        type: `SET_DELIVERY_NOTES`,
        data: deliveryNotes
      })
      dispatch({
        type: `SET_DELIVERY_NOTES_LOADING`,
        data: false
      })
    } catch (e) {
      console.log(e)
      toast.error(`There was an error fetching delivery notes. Please try again later.`)
      dispatch({
        type: `SET_DELIVERY_NOTES_LOADING`,
        data: false
      })
    }
  }
}

// Update dispatch date
export const updateDispatchDate = (id, dispatchDate) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/delivery-note/${id}`,
        {
          dispatchDate
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      const newDeliveryNotes = getState().deliveryNotes.deliveryNotes.map(
        dn => {
          if (dn._id === id) {
            dn.dispatchDate = dispatchDate
          }

          return dn
        }
      )

      dispatch({
        type: `SET_DELIVERY_NOTES`,
        data: newDeliveryNotes
      })
      toast.success('Success: Dispatch date updated!')
    } catch (e) {
      toast.error('Error: ' + e.response.data.error)
    }
  }
}

// Update delivery method
export const updateDeliveryMethod = (id, deliveryMethod) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/delivery-note/${id}`,
        {
          deliveryMethod
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      const newDeliveryNotes = getState().deliveryNotes.deliveryNotes.map(
        dn => {
          if (dn._id === id) {
            dn.deliveryMethod = deliveryMethod
          }

          return dn
        }
      )

      dispatch({
        type: `SET_DELIVERY_NOTES`,
        data: newDeliveryNotes
      })

      toast.success('Success: Delivery method updated!')
    } catch (e) {
      toast.error('Error: ' + e.response.data.error)
    }
  }
}

// Update Delivery Note status
export const updateDeliveryNoteStatus = (id, status) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/delivery-note/${id}`,
        {
          status
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      const newDeliveryNotes = getState().deliveryNotes.deliveryNotes.map(
        dn => {
          if (dn._id === id) {
            dn.status = status
          }

          return dn
        }
      )

      dispatch({
        type: `SET_DELIVERY_NOTES`,
        data: newDeliveryNotes
      })

      toast.success('Success: Delivery note status updated!')
    } catch (e) {
      toast.error('Error: ' + e.response.data.error)
    }
  }
}
