import React, { useState } from "react"
import { connect } from "react-redux"
import { Modal, Button, Form } from "react-bootstrap"
import { FaWindowClose, FaSearch } from "react-icons/fa"
import { setProductSerialNumbers } from "../../state/actions/invoice"

import styles from "../../styles/components/invoices/serialNumberModal.module.scss"

const SerialNumberModal = ({
  id,
  product,
  setProductSerialNumbers,
  serialNumbersInInvoice,
}) => {
  const [show, setShow] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  const handleSerialNumberChange = serial => {
    if (serialNumbersInInvoice.includes(serial)) {
      setProductSerialNumbers(
        id,
        serialNumbersInInvoice.filter(sn => sn !== serial)
      )
    } else {
      setProductSerialNumbers(id, [...serialNumbersInInvoice, serial])
    }
  }

  return (
    <>
      <Button onClick={() => setShow(true)} variant="secondary">
        Add
      </Button>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title>Add Serial Numbers</Modal.Title>
          <Button
            tabIndex={2}
            variant="danger"
            onClick={() => setShow(false)}
            onKeyDown={() => setShow(false)}
          >
            <FaWindowClose />
          </Button>
        </Modal.Header>
        <Modal.Body className="h-100">
          {/* Search */}
          <div className={styles.search}>
            <input
              type="text"
              value={searchTerm}
              name="search"
              placeholder="Search"
              className={styles.searchInput}
              onChange={e => {
                setSearchTerm(e.target.value)
              }}
              autoComplete="off"
            />
            <FaSearch className={styles.searchIcon} />
          </div>

          {/* Serial Numbers */}
          {product.serialNumbers?.length ? (
            <div>
              {product.serialNumbers
                .filter(serialNumber => {
                  const search = searchTerm.toLowerCase()
                  const mainSerial = serialNumber.main.toLowerCase()
                  const complimentarySerial =
                    serialNumber.complimentary?.toLowerCase() || ""

                  if (
                    mainSerial.includes(search) ||
                    complimentarySerial.includes(search)
                  ) {
                    return serialNumber
                  } else return null
                })
                .sort((a, b) => {
                  if (
                    serialNumbersInInvoice.includes(a) &&
                    !serialNumbersInInvoice.includes(b)
                  ) {
                    return -1
                  } else if (
                    !serialNumbersInInvoice.includes(a) &&
                    serialNumbersInInvoice.includes(b)
                  ) {
                    return 1
                  } else {
                    return 0
                  }
                })
                .map(serialNumber => (
                  <Form.Group key={serialNumber.main}>
                    <Form.Check
                      className={styles.serialNumber}
                      type="checkbox"
                      label={`${serialNumber.main} ${
                        serialNumber.complimentary
                          ? serialNumber.complimentary
                          : " "
                      }`}
                      checked={serialNumbersInInvoice.includes(serialNumber)}
                      onChange={() => handleSerialNumberChange(serialNumber)}
                    />
                  </Form.Group>
                ))}
            </div>
          ) : (
            <div>No serial numbers available for this product</div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = (state, props) => ({
  product: state.invoice.invoiceProducts.find(prod => prod.id === props.id),
  serialNumbersInInvoice: state.invoice.invoiceProducts.find(
    prod => prod.id === props.id
  )?.serialNumbersInInvoice,
})

const mapDispatchToProps = dispatch => ({
  setProductSerialNumbers: (id, serialNumbers) =>
    dispatch(setProductSerialNumbers(id, serialNumbers)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SerialNumberModal)
