import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import BackButton from './backButton'
import { connect } from 'react-redux'
import { Col, Button, Table } from 'react-bootstrap'
import { editCustomer, deleteCustomer } from '../state/actions/customer'
import { confirmAlert } from 'react-confirm-alert'
import { formatNumber, getTotal } from '../state/utils'

// Styles
import styles from '../styles/components/editCustomer.module.scss'
import { navigate } from 'gatsby-link'
import { CollectionsOutlined } from '@material-ui/icons'

const EditCustomer = ({
  customer,
  editCustomer,
  deleteCustomer,
  invoices,
  paymentReceipts,
  admin,
  canEditCustomer
}) => {
  const [edit, toggleEdit] = useState(false)
  const [customerCode, editCustomerCode] = useState(customer.code || '')
  const [customerName, editCustomerName] = useState(customer.name || '')
  const [customerAddress, editCustomerAddress] = useState(
    customer.address || ''
  )
  const [customerTel, editCustomerTel] = useState(customer.telephone || '')

  const [customerEmail, editCustomerEmail] = useState(customer.email || '')

  const invoiceTotal = invoices
    .filter(invoice => invoice.customerCode === customer.code)
    .reduce((total, invoice) => total + getTotal(invoice.invoiceProducts), 0)

  const paymentReceiptTotal = paymentReceipts
    .filter(receipt => receipt.customerCode === customer.code)
    .reduce((total, receipt) => total + receipt.amount, 0)

  const handleUpdateCustomer = () => {
    if (!admin && !canEditCustomer) return

    const data = {
      id: customer._id,
      customerData: {
        code: customerCode,
        name: customerName,
        address: customerAddress,
        telephone: customerTel
      }
    }
    if (customerEmail) {
      data.customerData.email = customerEmail
    }
    editCustomer(data).then(success => {
      if (success) toggleEdit(false)
    })
  }

  const handleDeleteCustomer = () => {
    if (!admin && !canEditCustomer) return

    deleteCustomer(customer._id).then(success => {
      if (success) {
        navigate('/app/customers')
      }
    })
  }

  // Confirm Buttons

  const confirmUpdateCustomer = () => {
    confirmAlert({
      title: 'Update Customer',
      message: 'Are you sure you want to update this customer?',
      buttons: [
        {
          label: 'No'
        },
        {
          label: 'Yes',
          onClick: handleUpdateCustomer
        }
      ]
    })
  }

  const confirmDeleteCustomer = () => {
    confirmAlert({
      title: 'Delete Customer',
      message: 'Are you sure you want to delete this customer?',
      buttons: [
        {
          label: 'No'
        },
        {
          label: 'Yes',
          onClick: handleDeleteCustomer
        }
      ]
    })
  }

  return (
    <div className={styles.wrapper}>
      <Col>
        <h1 className='title'>
          Customer <span>{customer.code}</span>
        </h1>
        <BackButton to='/app/customers' />
        <Table>
          <tbody className={styles.table}>
            <tr>
              <td className={styles.label}>Customer Code</td>
              {!edit ? (
                <td>{customer.code}</td>
              ) : (
                <td>
                  <input
                    type='text'
                    name='customerCode'
                    value={customerCode}
                    onChange={e => {
                      editCustomerCode(e.target.value.toUpperCase())
                    }}
                    id='customerCode'
                    autoFocus
                  />
                </td>
              )}
            </tr>
            <tr>
              <td className={styles.label}>Name</td>
              {!edit ? (
                <td>{customer.name}</td>
              ) : (
                <td>
                  <input
                    type='text'
                    name='customerName'
                    value={customerName}
                    onChange={e => {
                      editCustomerName(e.target.value)
                    }}
                    id='customerName'
                    autoFocus
                  />
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>Address</td>
              {!edit ? (
                <td>{customer.address}</td>
              ) : (
                <td>
                  <input
                    type='text'
                    name='customerAddress'
                    value={customerAddress}
                    onChange={e => {
                      editCustomerAddress(e.target.value)
                    }}
                    id='customerAddress'
                    autoFocus
                  />
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>Telephone</td>
              {!edit ? (
                <td>{customer.telephone}</td>
              ) : (
                <td>
                  <input
                    type='tel'
                    name='customerTel'
                    value={customerTel}
                    onChange={e => {
                      editCustomerTel(e.target.value)
                    }}
                    id='customerTel'
                    autoFocus
                  />
                </td>
              )}
            </tr>

            <tr>
              <td className={styles.label}>Email</td>
              {!edit ? (
                <td>{customer.email}</td>
              ) : (
                <td>
                  <input
                    type='email'
                    name='customerEmail'
                    value={customerEmail}
                    onChange={e => {
                      editCustomerEmail(e.target.value)
                    }}
                    id='customerEmail'
                    autoFocus
                  />
                </td>
              )}
            </tr>
          </tbody>
        </Table>
      </Col>
      {(admin || canEditCustomer) && (
        <div className='action-buttons'>
          <Button
            size='lg'
            style={{ marginRight: '6px' }}
            variant='light'
            onClick={() => {
              toggleEdit(!edit)
            }}
          >
            {edit ? 'Cancel' : 'Edit Customer'}
          </Button>
          {edit && (
            <Button size='lg' onClick={confirmUpdateCustomer}>
              Save
            </Button>
          )}
          {!edit && (
            <Button
              size='lg'
              style={{ marginRight: '6px' }}
              variant='danger'
              onClick={confirmDeleteCustomer}
            >
              Delete Customer
            </Button>
          )}
        </div>
      )}
      <div>
        <h3 className='mt-5'>Transaction Summary</h3>
        <Table className='mt-3'>
          <tbody>
            <tr>
              <td className='font-weight-bold'>Payment Receipt Total</td>
              <td>LKR {formatNumber(paymentReceiptTotal)}</td>
            </tr>
            <tr>
              <td className='font-weight-bold'>Invoice Total</td>
              <td>LKR {formatNumber(invoiceTotal)}</td>
            </tr>
            <tr style={{ borderTop: '2px solid black' }}>
              <td className='font-weight-bold'>Balance</td>
              <td
                className={
                  paymentReceiptTotal - invoiceTotal < 0
                    ? 'text-danger'
                    : 'text-success'
                }
              >
                LKR {formatNumber(paymentReceiptTotal - invoiceTotal)}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  )
}

EditCustomer.propTypes = {
  customer: PropTypes.object.isRequired
}

// Specifies the default values for props:
EditCustomer.defaultProps = {
  customer: {
    code: '',
    name: '',
    address: '',
    telephone: '',
    email: ''
  }
}

const mapStateToProps = (state, props) => {
  return {
    customer: state.customer.customers.find(
      customer => customer._id === props.id
    ),
    invoices: state.invoices.invoices.filter(
      invoice => invoice.cancelled === false
    ),
    paymentReceipts: state.paymentReceipts.paymentReceipts,
    admin: state.auth.auth.user.admin,
    canEditCustomer: state.auth.auth.user.customers
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editCustomer: data => dispatch(editCustomer(data)),
    deleteCustomer: data => dispatch(deleteCustomer(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer)
