import React, { useState } from "react"
import { connect } from "react-redux"
import { Row, Col, Button, Table } from "react-bootstrap"
import { updatePrice, updateCost } from "../state/actions/product"
import { SyncLoader } from "react-spinners"
import { formatNumber } from "../state/utils"
import BackButton from "./backButton"
import AddStock from "./inventory/addStock"
import RemoveStock from "./inventory/removeStock"

// Styles
import styles from "../styles/components/editProduct.module.scss"

const EditProduct = ({ product, updatePrice, loading, updateCost, admin }) => {
  const [edit, toggleEdit] = useState(false)
  const [editPriceValue, setEditPriceValue] = useState(product.price)

  const [editCost, setEditCost] = useState(false)
  const [editCostValue, setEditCostValue] = useState(product.cost)

  const handleupdatePrice = e => {
    updatePrice({ productSKU: product.sku, price: editPriceValue })
    toggleEdit(false)
  }

  const handleUpdateCost = e => {
    updateCost({ productSKU: product.sku, cost: editCostValue })
    setEditCost(false)
  }

  return (
    <div className={styles.wrapper}>
      <h1 className="title">
        Product <span>{product.sku}</span>
      </h1>
      <BackButton to="/app/products" />
      {product && (
        <Row className={styles.content}>
          <Col lg={3}>
            {product.productImages &&
              product.productImages.map((image, i) => (
                <div key={i} className={styles.image}>
                  <img
                    src={image.fields.file.url}
                    alt={image.fields.description}
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      height: "auto",
                    }}
                  />
                </div>
              ))}
          </Col>
          {loading ? (
            <Col
              style={{ textAlign: "center", display: "block", margin: "auto" }}
            >
              <SyncLoader loading={loading} color="#2980b9" size={20} />
            </Col>
          ) : (
            <Col>
              <Table>
                <tbody>
                  <tr>
                    <td className={styles.label}>SKU</td>
                    <td> {product.sku}</td>
                  </tr>
                  <tr>
                    <td className={styles.label}>Title</td>
                    <td>{product.productName}</td>
                  </tr>

                  <tr>
                    <td className={styles.label}>Description</td>
                    <td>{product.productDescription}</td>
                  </tr>

                  <tr>
                    <td className={styles.label}>Category</td>
                    <td>{product.category}</td>
                  </tr>

                  <tr>
                    <td className={styles.label}>Manufacturer</td>
                    <td>{product.manufacturer}</td>
                  </tr>

                  <tr>
                    <td className={styles.label}>Warranty</td>
                    <td>{product.warranty}</td>
                  </tr>

                  <tr>
                    <td className={styles.label}>Country of Origin</td>
                    <td>{product.countryOfOrigin}</td>
                  </tr>

                  <tr>
                    <td className={styles.label}>Stock in Hand</td>
                    <td>{product.stock}</td>
                  </tr>

                  <tr>
                    <td className={styles.label}>Stock in Transit</td>
                    <td>{product.stockInTransit}</td>
                  </tr>

                  {admin && (
                    <tr>
                      <td className={styles.label}>Cost</td>
                      {!editCost ? (
                        <td>{formatNumber(product.cost)}</td>
                      ) : (
                        <td>
                          <input
                            type="number"
                            name="editCost"
                            value={editCostValue}
                            onChange={e => setEditCostValue(e.target.value)}
                            id="editCost"
                            autoFocus
                          />
                        </td>
                      )}
                    </tr>
                  )}

                  <tr>
                    <td className={styles.label}>Price</td>
                    {!edit ? (
                      <td>{formatNumber(product.price)}</td>
                    ) : (
                      <td>
                        <input
                          type="number"
                          name="editPrice"
                          value={editPriceValue}
                          onChange={e => {
                            setEditPriceValue(e.target.value)
                          }}
                          id="editPrice"
                          autoFocus
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            </Col>
          )}
        </Row>
      )}
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    product: state.product.products.find(product => product.id === props.id),
    loading: state.product.editProductLoading,
    admin: state.auth.auth.user.admin,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updatePrice: data => dispatch(updatePrice(data)),
    updateCost: data => dispatch(updateCost(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct)
