import _ from "lodash"
import axios from "axios"
import { toast } from "react-toastify"

export const setProducts = () => {
  return async (dispatch, getState) => {
    if (getState().product.products && getState().product.products.length > 0) {
      return
    }
    try {
      const data = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}&content_type=product&limit=1000&order=-fields.price`
      )
      const images = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/master/assets?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}&limit=1000`
      )

      const token = getState().auth.auth.token

      // Get product price, stock, and serial numbers from database
      const fromDatabase = await axios.get(
        `${process.env.GATSBY_API_CALL}/products`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const productsFromDB = fromDatabase.data

      let newProducts = []

      _.forEach(data.data.items, product => {
        let imageArray = []

        _.forEach(product.fields.productImages, image => {
          const imageLocation = images.data.items.find(asset => {
            return asset.sys.id === image.sys.id
          })
          imageArray.push(imageLocation)
        })
        // if (!product.fields.price) {
        //   product.fields.price = parseFloat(0.0)
        // }

        const productFromDB = productsFromDB.find(
          prod => prod.sku === product.fields.sku
        )

        product.fields.cost = parseFloat(productFromDB.cost)
        product.fields.price = parseFloat(productFromDB.price)
        product.fields.productImages = imageArray
        product.fields.id = product.sys.id
        product.fields.stock = productFromDB.stock
        product.fields.serialNumbers = productFromDB.serialNumbers
        product.fields.stockInTransit = productFromDB.stockInTransit
        product.fields.serialNumbersInTransit =
          productFromDB.serialNumbersInTransit
        product.fields.stockSold = productFromDB.stockSold
        product.fields.serialNumbersSold = productFromDB.serialNumbersSold

        newProducts.push(product.fields)
        return
      })

      dispatch({
        type: "SET_PRODUCTS",
        data: newProducts,
      })
    } catch (e) {
      console.log(e)
      toast.error(`Error. ${e.response?.data.message}`)
    }
  }
}

// Get price, cost, stock and serial numbers from database
export const setProductsFromDatabase = () => {
  return async (dispatch, getState) => {
    try {
      const token = getState().auth.auth.token

      const fromDatabase = await axios.get(
        `${process.env.GATSBY_API_CALL}/products`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const productsFromDB = fromDatabase.data

      const newProducts = getState().product.products.map(product => {
        const productFromDB = productsFromDB.find(
          prod => prod.sku === product.sku
        )

        product.cost = parseFloat(productFromDB.cost)
        product.price = parseFloat(productFromDB.price)
        product.stock = productFromDB.stock
        product.serialNumbers = productFromDB.serialNumbers
        product.stockInTransit = productFromDB.stockInTransit
        product.serialNumbersInTransit = productFromDB.serialNumbersInTransit
        product.stockSold = productFromDB.stockSold
        product.serialNumbersSold = productFromDB.serialNumbersSold

        return product
      })

      dispatch({
        type: "SET_PRODUCTS",
        data: newProducts,
      })
    } catch (e) {
      toast.error(`Error. ${e.response?.data.message}`)
    }
  }
}

// Update Price
export const updatePrice = ({ productSKU, price }) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    dispatch({
      type: "SET_EDIT_PRODUCT_LOADING",
      data: true,
    })
    const productData = {
      price: parseFloat(price),
    }

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/product/price/${productSKU}`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const newProducts = [...getState().product.products]
      _.forEach(newProducts, product => {
        if (product.sku === productSKU) {
          product.price = price
        }
      })

      dispatch({
        type: "SET_PRODUCTS",
        data: newProducts,
      })
      toast.success(`Price successfully updated`)
    } catch (e) {
      toast.error(`Error. ${e.response.data.message}`)
    }
    dispatch({
      type: "SET_EDIT_PRODUCT_LOADING",
      data: false,
    })
  }
}

// Update Cost
export const updateCost = ({ productSKU, cost }) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    dispatch({
      type: "SET_EDIT_PRODUCT_LOADING",
      data: true,
    })

    const productData = {
      cost: parseFloat(cost),
    }

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/product/price/${productSKU}`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newProducts = [...getState().product.products]
      _.forEach(newProducts, product => {
        if (product.sku === productSKU) {
          product.cost = cost
        }
      })

      dispatch({
        type: "SET_PRODUCTS",
        data: newProducts,
      })

      toast.success(`Cost successfully updated`)
    } catch (e) {
      console.log(e.response)
      toast.error(`Error. ${e.response}`)
    }
    dispatch({
      type: "SET_EDIT_PRODUCT_LOADING",
      data: false,
    })
  }
}

// Update Stock
export const updateStock = ({ productSKU, stock, serialNumbers }) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    dispatch({
      type: "SET_EDIT_PRODUCT_LOADING",
      data: true,
    })
    const productData = {
      stock: stock,
      serialNumbers: serialNumbers,
    }

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/product/${productSKU}`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newProducts = [...getState().product.products]
      _.forEach(newProducts, product => {
        if (product.sku === productSKU) {
          product.stock = stock
          product.serialNumbers = serialNumbers
        }
      })

      dispatch({
        type: "SET_PRODUCTS",
        data: newProducts,
      })
      toast.success(`Stock successfully updated`)
    } catch (e) {
      toast.error(`Error. ${e.response.data.message}`)
    }
    dispatch({
      type: "SET_EDIT_PRODUCT_LOADING",
      data: false,
    })
  }
}

// Update Price in batch
export const updatePriceInBatch = productList => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    const products = getState().product.products

    var updateCount = 0

    const promises = productList.map(async product => {
      // Check if required data exists
      if (!product.sku || (!product.price && !product.cost)) return

      // Check if product exists
      if (!products.find(prod => prod.sku === product.sku)) return

      dispatch({
        type: "SET_EDIT_PRODUCT_LOADING",
        data: true,
      })

      const productData = {
        ...(product.price !== undefined && {
          price: parseFloat(product.price),
        }),
        ...(product.cost !== undefined && { cost: parseFloat(product.cost) }),
      }

      try {
        await axios.patch(
          `${process.env.GATSBY_API_CALL}/product/price/${product.sku}`,
          productData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        updateCount++

        const newProducts = [...getState().product.products]
        _.forEach(newProducts, prod => {
          if (prod.sku === product.sku) {
            if (product.price !== undefined) prod.price = product.price

            if (product.cost !== undefined) prod.cost = product.cost
          }
        })

        dispatch({
          type: "SET_PRODUCTS",
          data: newProducts,
        })
      } catch (e) {
        console.log(e.response.data)
      }
      dispatch({
        type: "SET_EDIT_PRODUCT_LOADING",
        data: false,
      })
    })

    await Promise.all(promises)
    toast.success(`${updateCount} products updated successfully`)
  }
}
