import React, { useState, UseEffect, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Table } from 'react-bootstrap'
import { HiOutlineDocumentAdd } from 'react-icons/hi'
import { navigate } from 'gatsby'
import moment from 'moment'
import { FaSearch, FaCashRegister } from 'react-icons/fa'
import {
  getPaymentReceipts,
  clearNewPaymentReceipt
} from '../../state/actions/paymentReceipts'
import { setUsers } from '../../state/actions/user'
import { formatNumber } from '../../state/utils'
import Loading from '../loading'

import styles from '../../styles/components/paymentReceipts/paymentReceipts.module.scss'

const PaymentReceipts = ({
  paymentReceipts,
  customers,
  users,
  getPaymentReceipts,
  setUsers,
  clearNewPaymentReceipt
}) => {
  useEffect(() => {
    setUsers()
    getPaymentReceipts()
  }, [getPaymentReceipts])

  const [searchTerm, setSearchTerm] = useState('')

  // if (paymentReceipts.length === 0) return <Loading />

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h1 className='title'>
          <FaCashRegister />
          Payment Receipts
        </h1>
        <div className='action-buttons'>
          <Button
            size='lg'
            variant='success'
            onClick={() => {
              clearNewPaymentReceipt()
              navigate('/app/payment-receipts/new')
            }}
          >
            <HiOutlineDocumentAdd
              className='mb-1 mr-2'
              style={{ fontSize: '16px' }}
            />
            New Payment Receipt
          </Button>
        </div>
      </div>
      <div className='mt-5'>
        {paymentReceipts.length ? (
          <>
            {/* Search */}
            <div className={styles.search}>
              <input
                type='text'
                value={searchTerm}
                name='search'
                placeholder='Search'
                autoComplete='off'
                className={styles.searchInput}
                onChange={e => {
                  setSearchTerm(e.target.value)
                }}
              />
              <FaSearch className={styles.searchIcon} />
            </div>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Payment Receipt Number</th>
                  <th>Customer</th>
                  <th>Date</th>
                  <th>Created By</th>
                  <th>Amount Received</th>
                </tr>
              </thead>
              <tbody>
                {paymentReceipts
                  .filter(paymentReceipt => {
                    const search = searchTerm.trim().toLowerCase()
                    const receiptNum = paymentReceipt.paymentReceiptNumber
                      .trim()
                      .toLowerCase()
                    const cusCode = paymentReceipt.customerCode
                      .trim()
                      .toLowerCase()
                    const cusName =
                      customers
                        .find(
                          customer =>
                            customer.code === paymentReceipt.customerCode
                        )
                        ?.name.trim()
                        .toLowerCase() || ''
                    const date = paymentReceipt.date.trim()
                    const uploader = users
                      .find(user => user._id === paymentReceipt.uploader)
                      ?.name.trim()
                      .toLowerCase()

                    if (
                      receiptNum.includes(search) ||
                      cusCode.includes(search) ||
                      cusName.includes(search) ||
                      date.includes(search) ||
                      uploader.includes(search)
                    ) {
                      return paymentReceipt
                    }
                  })
                  .reverse()
                  .map(paymentReceipt => (
                    <tr
                      key={paymentReceipt._id}
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        navigate(
                          `/app/payment-receipts/${paymentReceipt.paymentReceiptNumber}`
                        )
                      }
                    >
                      <td>{paymentReceipt.paymentReceiptNumber}</td>
                      <td>
                        {paymentReceipt.customerCode} -{' '}
                        {
                          customers.find(
                            customer =>
                              customer.code === paymentReceipt.customerCode
                          )?.name
                        }
                      </td>
                      <td>
                        {moment(new Date(paymentReceipt.date)).format(
                          'DD-MM-YYYY'
                        )}
                      </td>
                      <td>
                        {
                          users.find(
                            user => user._id === paymentReceipt.uploader
                          )?.name
                        }
                      </td>
                      <td>{`LKR ${formatNumber(+paymentReceipt.amount)}`}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        ) : (
          <p className='text-muted'>No Payment Receipts</p>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    paymentReceipts: state.paymentReceipts.paymentReceipts,
    users: state.user.users,
    customers: state.customer.customers
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPaymentReceipts: () => dispatch(getPaymentReceipts()),
    setUsers: () => dispatch(setUsers()),
    clearNewPaymentReceipt: () => dispatch(clearNewPaymentReceipt())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReceipts)
