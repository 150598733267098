import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import Table from "../components/table"
import NewInvoice from "../components/invoices/newInvoice"
import Invoices from "../components/invoices/invoices"
import ViewInvoice from "../components/invoices/viewInvoice"
import Customers from "../components/customers"
import EditProduct from "../components/editProduct"
import Login from "../components/login"
import EditCustomer from "../components/editCustomer"
import Quotations from "../components/quotations/quotations"
import NewQuotation from "../components/quotations/newQuotation"
import ViewQuotation from "../components/quotations/viewQuotation"
import DeliveryNotes from "../components/deliveryNotes/deliveryNotes"
import ViewDeliveryNote from "../components/deliveryNotes/viewDeliveryNote"
import PaymentReceipts from "../components/paymentReceipts/paymentReceipts"
import NewPaymentReceipt from "../components/paymentReceipts/newPaymentReceipt"
import ViewPaymentReceipt from "../components/paymentReceipts/viewPaymentReceipt"
import Sales from "../components/sales/sales"
import Inventory from "../components/inventoryReport/inventory"
import Reports from "../components/reports/reports"

const App = () => {
  return (
    <Layout>
      <Router>
        <PrivateRoute component={Table} path="/app/products" />
        <PrivateRoute component={EditProduct} path="/app/edit-product/:id" />

        <PrivateRoute component={Invoices} path="/app/invoices" />
        <PrivateRoute component={ViewInvoice} path="/app/invoices/:id" />
        <PrivateRoute component={NewInvoice} path="/app/invoices/new" />

        <PrivateRoute component={Customers} path="/app/customers" />
        <PrivateRoute component={EditCustomer} path="/app/edit-customer/:id" />

        <PrivateRoute component={Quotations} path="/app/quotations" />
        <PrivateRoute component={ViewQuotation} path="/app/quotations/:id" />
        <PrivateRoute component={NewQuotation} path="/app/quotations/new" />

        <PrivateRoute component={DeliveryNotes} path="/app/delivery-notes" />
        <PrivateRoute
          component={ViewDeliveryNote}
          path="/app/delivery-notes/:id"
        />

        <PrivateRoute
          component={PaymentReceipts}
          path="/app/payment-receipts"
        />
        <PrivateRoute
          component={NewPaymentReceipt}
          path="/app/payment-receipts/new"
        />
        <PrivateRoute
          component={ViewPaymentReceipt}
          path="/app/payment-receipts/:id"
        />
        <PrivateRoute
          component={Sales}
          path="/app/sales"
        />
         <PrivateRoute
          component={Reports}
          path="/app/reports"
        />
        <PrivateRoute
          component={Inventory}
          path="/app/inventory-report"
        />

        <Login default />
      </Router>
    </Layout>
  )
}

export default App
