import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import {
  FaArrowLeft,
  FaArrowRight,
  FaSearch,
  FaFileInvoice,
} from "react-icons/fa"
import { Table, Row, Button } from "react-bootstrap"
import moment from "moment"
import { navigate } from "gatsby"
import { HiOutlineDocumentAdd } from "react-icons/hi"
import { setInvoices } from "../../state/actions/invoices"
import { clearInvoice } from "../../state/actions/invoice"
import { setUsers } from "../../state/actions/user"
import Loading from "../loading"

import styles from "../../styles/components/invoices/invoices.module.scss"

const Invoices = props => {
  const [currentPage, setCurrentPage] = useState(1)
  const [invoicesPerPage, setInvoicesPerPage] = useState(25)
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    props.setInvoices()
    props.setUsers()
  }, [])

  const invoiceClickHandler = id => {
    navigate(`/app/invoices/${id}`)
  }

  const filteredInvoices = props.invoices.filter(invoice => {
    const search = searchTerm.trim().toLowerCase()
    const invNum = invoice.invoiceNumber.trim().toLowerCase()
    const cusCode = invoice.customerCode.trim().toLowerCase()
    const cusName =
      props.customers
        .find(customer => customer.code === invoice.customerCode)
        ?.name.trim()
        .toLowerCase() || ""
    const date = invoice.date.trim()
    const uploader = props.users
      .find(user => user._id === invoice.uploader)
      ?.name.trim()
      .toLowerCase()

    if (
      invNum?.includes(search) ||
      cusCode?.includes(search) ||
      cusName?.includes(search) ||
      date?.includes(search) ||
      uploader?.includes(search)
    ) {
      return invoice
    } else return null
  })

  const indexOfLastInvoice = currentPage * invoicesPerPage
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage
  const currentInvoices = filteredInvoices.slice(
    indexOfFirstInvoice,
    indexOfLastInvoice
  )

  // if (props.invoices.length === 0) {
  //   return <Loading />
  // }

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="title">
          <FaFileInvoice />
          Invoices
        </h1>
        <div className="action-buttons">
          <Button
            size="lg"
            variant="success"
            onClick={() => {
              props.clearInvoice()
              localStorage.removeItem("quatationToInvoice")
              navigate("/app/invoices/new")
            }}
          >
            <HiOutlineDocumentAdd
              className="mb-1 mr-2"
              style={{ fontSize: "16px" }}
            />
            New Invoice
          </Button>
        </div>
      </div>
      <div className="mt-5">
        {/* Search */}
        <div className={styles.search}>
          <input
            type="text"
            value={searchTerm}
            name="search"
            placeholder="Search"
            autoComplete="off"
            className={styles.searchInput}
            onChange={e => {
              setSearchTerm(e.target.value)
              setCurrentPage(1)
            }}
          />
          <FaSearch className={styles.searchIcon} />
        </div>

        {filteredInvoices.length ? (
          <>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Customer Code</th>
                  <th>Customer Name</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {currentInvoices.map((invoice, idx) => {
                  return (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={idx}
                      onClick={() => invoiceClickHandler(invoice._id)}
                    >
                      <td>{invoice.invoiceNumber}</td>
                      <td>{invoice.customerCode}</td>
                      <td>
                        {
                          props.customers.find(
                            customer => customer.code === invoice.customerCode
                          )?.name
                        }
                      </td>
                      <td>
                        {moment(new Date(invoice.date)).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {invoice.cancelled ? (
                          <span className="text-danger">Cancelled</span>
                        ) : invoice.isCanceled ? (
                          <span className="text-danger">
                            Request for cancel
                          </span>
                        ) : (
                          <span className="text-success">Active</span>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            {/* Pagination */}
            <Row className="d-flex justify-content-center align-items-center my-4 ">
              <div className=" d-flex align-items-center mx-4 my-4 my-lg-0">
                {/* Dropdown to change items per page */}
                <h3
                  className="small d-inline mr-2"
                  style={{
                    minWidth: "105px",
                  }}
                >
                  Items per page:
                </h3>
                <select
                  className="form-control form-control-lg"
                  value={invoicesPerPage}
                  onChange={e => {
                    setCurrentPage(1)
                    setInvoicesPerPage(e.target.value)
                  }}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <Row className="d-flex justify-content-center align-items-center mx-4">
                <Button
                  size="lg"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  variant={currentPage === 1 ? "secondary" : "primary"}
                >
                  <FaArrowLeft />
                </Button>

                <span className="mx-3 small">
                  Page {currentPage} /{" "}
                  {
                    // Total pages
                    Math.ceil(filteredInvoices.length / invoicesPerPage)
                  }
                </span>

                <Button
                  size="lg"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={indexOfLastInvoice >= filteredInvoices.length}
                >
                  <FaArrowRight />
                </Button>
              </Row>
            </Row>
          </>
        ) : (
          <p className="text-muted">No invoices available!</p>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    invoices: state.invoices.invoices,
    isLoading: state.invoices.isLoading,
    customers: state.customer.customers,
    users: state.user.users,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setInvoices: () => dispatch(setInvoices()),
    setUsers: () => dispatch(setUsers()),
    clearInvoice: () => dispatch(clearInvoice()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
